@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* Override Tailwind base styles for the .no-tailwind class */
.no-tailwind h1,
.no-tailwind h2,
.no-tailwind h3,
.no-tailwind h4,
.no-tailwind h5,
.no-tailwind h6,
.no-tailwind p,
.no-tailwind ul,
.no-tailwind ol,
.no-tailwind li,
.no-tailwind br,
.no-tailwind a,
.no-tailwind blockquote,
.no-tailwind strong,
.no-tailwind em,
.no-tailwind code,
.no-tailwind pre,
.no-tailwind hr,
.no-tailwind table,
.no-tailwind thead,
.no-tailwind tbody,
.no-tailwind tr,
.no-tailwind th,
.no-tailwind td,
.no-tailwind img,
.no-tailwind figure,
.no-tailwind figcaption {
  /* Remove all styles applied by Tailwind */
  all: revert;
  /* Optionally, apply default browser styles */
  margin: revert;
  padding: revert;
  font-size: revert;
  font-weight: revert;
  color: revert;
  text-decoration: revert;
  list-style: revert;
  border: revert;
  background: revert;
  display: revert;
  line-height: revert;
  text-align: revert;
  white-space: revert;
}

/* Additional specific overrides for markdown elements */
.no-tailwind blockquote {
  border-left: 4px solid #ccc;
  padding-left: 1em;
  margin-left: 0;
}

.no-tailwind pre,
.no-tailwind code {
  background-color: #f4f4f4;
  padding: 0.2em 0.4em;
  border-radius: 3px;
}

.no-tailwind hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 1em 0;
}

.no-tailwind table {
  border-collapse: collapse;
  width: 100%;
}

.no-tailwind th,
.no-tailwind td {
  border: 1px solid #ccc;
  padding: 0.5em;
}

.no-tailwind img {
  max-width: 100%;
  height: auto;
}
